<template>
    <b-overlay :show="formShow" rounded="sm" no-fade>
        <b-card>
            <b-row>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Hotel</th>
                        </tr>
                    </thead>
                </table>
            </b-row>

            <validation-observer ref="hotelRules">
                <b-form>
                    <b-row class="mt-2">
                        <!-- Name -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="Title">Hotel Name</label>
                                <validation-provider name="Title" #default="{ errors }" rules="required">
                                    <b-form-input id="title" type="text" v-model="hotelData.title"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="price">Hotel Price</label>
                                <validation-provider name="Price" #default="{ errors }" rules="required">
                                    <!-- <b-form-input id="price" type="number" v-model="hotelData.price" :state="errors.length > 0 ? false : null"></b-form-input> -->
                                    <cleave id="price" v-model="hotelData.price" :state="errors.length > 0 ? false : null" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-button v-if="hotelId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                                Save
                            </b-button>
                            <b-button v-else type="submit" @click.prevent="formSubmitted" v-show="hotelData.status != 'deleted'" variant="primary" class="mr-1 float-right"> Save Changes </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card>
    </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";

import store from "@/store";
import hotelStoreModule from "../hotelStoreModule";

import { required, alphaNum, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import router from "@/router";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BRow,
        BCol,

        vSelect,
        Cleave,
        ToastificationContent,

        ValidationProvider,
        ValidationObserver,
        flatPickr,
    },

    directives: {
        Ripple,
        "b-modal": VBModal,
    },

    setup() {
        const HOTEL_APP_STORE_MODULE_NAME = "hotel";

        // Register module
        if (!store.hasModule(HOTEL_APP_STORE_MODULE_NAME)) store.registerModule(HOTEL_APP_STORE_MODULE_NAME, hotelStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(HOTEL_APP_STORE_MODULE_NAME)) store.unregisterModule(HOTEL_APP_STORE_MODULE_NAME);
        });
    },

    data: function() {
        var hotelData = {
            title: null,
            price: null,
        };

        return {
            userData: JSON.parse(localStorage.getItem("userData")),

            hotelData: hotelData,

            baseURL: store.state.app.baseURL,
            required,
            alphaNum,
            email,

            hotelId: null,
            formShow: false,

            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                },

                percent: {
                    numeral: true,
                    numeralPositiveOnly: true,
                    blocks: [2],
                    prefix: "% ",
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 2,
                },
            },
        };
    },

    created() {
        this.getHotelById();
    },

    methods: {
        getHotelById() {
            this.formShow = true;

            if (router.currentRoute.params.id) {
                this.hotelId = parseInt(router.currentRoute.params.id);

                store
                    .dispatch("hotel/fetchHotel", { id: router.currentRoute.params.id })
                    .then((response) => {
                        if (response.status == 200) {
                            this.hotelData = response.data;

                            this.formShow = false;
                        } else {
                            router.push({ name: "error-404" });
                        }
                    })
                    .catch((error) => {
                        router.push({ name: "error-404" });
                    });
            } else {
                this.formShow = false;
            }
        },

        formSubmitted() {
            this.formShow = true;

            this.$refs.hotelRules.validate().then((success) => {
                if (success) {
                    if (router.currentRoute.params.id) {
                        store.dispatch("hotel/updateHotel", this.hotelData).then((response) => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Successful",
                                    text: "✔️ Hotel Updated Successfully",
                                    icon: "ThumbsUpIcon",
                                    variant: "success",
                                },
                            });

                            setTimeout(function() {
                                this.formShow = false;
                                router.push({ name: "hotel-list" });
                            }, 1000);
                        });
                    } else {
                        store.dispatch("hotel/addHotel", this.hotelData).then((response) => {
                            if (response.status == 200) {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Successful",
                                        text: "✔️ Hotel Added Successfully",
                                        icon: "ThumbsUpIcon",
                                        variant: "success",
                                    },
                                });

                                setTimeout(function() {
                                    this.formShow = false;
                                    router.push({ name: "hotel-list" });
                                }, 1000);
                            }
                        });
                    }
                } else {
                    this.formShow = false;
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
